<template>
  <v-card width="100%" elevation="0">
    <v-card-title class="">
      <strong>Organization</strong>
    </v-card-title>
    <v-row>
      <v-col cols="2" align="center" class="px-0">
        <v-img
          class="mx-4"
          style="border-radius: 10px"

          :src="require('../assets/profile.png')"
        >
        </v-img>
      </v-col>
      <v-col cols="4" class="px-0">
        <v-card-subtitle style="font-size: 30px" class="pa-0">
          {{ name }}

          <v-btn icon @click="routeTo('edit')">
            <v-icon> mdi-pencil-outline </v-icon>
          </v-btn>
        </v-card-subtitle>
        <v-card-subtitle style="font-size: 25px" class="pa-0">
          {{ number }}</v-card-subtitle
        >
        <v-card-subtitle style="font-size: 25px" class="pa-0">
          {{ email }}</v-card-subtitle
        >
        <br />

        <v-card-subtitle style="color: #797979; font-size: 25px" class="pa-0">
          Address
        </v-card-subtitle>
        <v-card-subtitle style="font-size: 25px" class="pa-0">
          {{ address }}</v-card-subtitle
        >
        <v-card-subtitle style="font-size: 25px" class="pa-0">
          {{ city }}</v-card-subtitle
        >
        <v-card-subtitle style="font-size: 25px" class="pa-0">
          {{ postal }}</v-card-subtitle
        >
      </v-col>
      <br />
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "OrganizationData",
  data: () => ({
    name: "None",
    number: "xxx-xxx-xxxx",
    email: "compliment@wecompliment.com",
    address: "1234 East North Street",
    city: "Lehi",
    postal: "12345",
  }),
  methods: {
    routeTo(loc) {
      this.$router.push(loc).catch(()=>{});
    },
  },
  mounted() {
    var client = JSON.parse(localStorage.getItem("clientDetails"));
    this.name = client.id;
    }
};
</script>