<template>
  <v-card width="80%" elevation="0">
    <v-form>
      <v-card-title class="ml-4">
        <strong>Edit Organization</strong>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="2" md="2" lg="2" align="center">
          <v-img
            class="ml-2"
            style="border-radius: 10px"
            :src="require('../assets/profile.png')"
          >
          </v-img>
          <v-btn text class="">
            <u>Upload Logo</u>
        </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-text-field :label="this.name" required outlined> </v-text-field>

          <v-text-field :label="number" required outlined> </v-text-field>

          <v-text-field :label="email" required outlined> </v-text-field>

          <v-text-field :label="address" required outlined> </v-text-field>

          <v-text-field :label="city" required outlined> </v-text-field>

          <v-text-field :label="postal" required outlined> </v-text-field>
          <v-row>
            <v-col cols="6">
              <v-btn @click="routeTo('view')" width="75%" class=""
                >Cancel</v-btn
              >
            </v-col>
            <v-col cols="6" align="end">
              <v-btn
                width="75%"
                class="px-0"
                style="background-color: #274543; color: white"
                @click="saveChanges"
                >Save Changes</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>


<script>
export default {
  name: "EditOrganization",
  data: () => ({
    name: "Test Data",
    number: "xxx-xxx-xxxx",
    email: "compliment@wecompliment.com",
    address: "1234 East North Street",
    city: "Lehi",
    postal: "12345",
  }),
  methods: {
    routeTo(loc) {
      this.$router.push(loc).catch(()=>{});
    },
    saveChanges() {
      console.log(this.name);
    },
  },
};
</script>