<template>
  <v-card class="pl-4" width="65%" elevation="0">
    <v-btn @click="routeTo()" text class="mt-2 pl-0">
      <v-card-subtitle class=""> <v-icon>mdi-arrow-left</v-icon> Back</v-card-subtitle>
    </v-btn>
    <v-row class="mb-4">
      <v-col cols="12" sm="3" md="3" lg="3" class="">
        <v-img
          class="mx-2 mt-2"
          style="border-radius: 10px"
          width="200px"
          height="200px"
          :src="require('../assets/profile.png')"
        >
        </v-img>
      </v-col>
      <v-col cols="12" sm="7" md="7" lg="7">
        <v-card-title class="py-0 pb-4 pl-0">
          {{ this.employee.name }}
          <v-btn
            elevation="0"
            icon
            right
            class="mr-10"
            absolute
            @click="editEmp = !editEmp"
            style="background-color: white"
          >
            <v-icon color="#797979">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-overlay :value="editEmp" light>
            <v-card
              light
              width="500px"
              height="300px"
              class="justify-center text-center align-center pt-10 pr-8"
        
            >
              <v-row justify="center">
                <v-col align="center">
                  <v-img
                    :src="require('../assets/profile.png')"
                    width="150px"
                    height="150px"
                    style="margin-top: auto; margin-bottom: auto"
                  >
                  </v-img>
                  <v-btn text
                    ><v-card-subtitle
                      ><u>Upload new Photo</u></v-card-subtitle
                    ></v-btn
                  >
                </v-col>
                <v-col class="">
                  <v-text-field
                    class=""
                    color="black"
                    :label="this.employee.name"
                  ></v-text-field>
                  <v-text-field
                    class=""
                    color="black"
                    :label="this.employee.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                style="background-color: #274543; color: white"
                class="mr-8"
                width="200px"
                >Save Changes</v-btn
              >

              <v-btn
                style="background-color: white; color: black"
                outlined
                width="200px"
                @click="editEmp = !editEmp"
                >Cancel</v-btn
              >
            </v-card>
          </v-overlay>
          <v-btn
            elevation="0"
            icon
            right
            absolute
            @click="deleteEmp = !deleteEmp"
            style="background-color: white"
          >
            <v-icon color="red">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-overlay :value="deleteEmp">
            <v-card
              class="text-center align-center pt-4"
              width="500px"
              height="300px"
              style="background-color: white"
            >
              <v-icon color="red">mdi-alert-circle-outline</v-icon>
              <v-card-title
                style="color: black; word-break: normal"
                class="mx-12"
              >
                Are you sure you want to delete this reports profile? All
                recognitions and data regarding this report will be lost.
              </v-card-title>
              <v-btn style="width: 250px; background-color: red" class="mb-4"
                >Yes, Delete Profile</v-btn
              >
              <br />
              <v-btn
                outlined
                @click="deleteEmp = !deleteEmp"
                style="width: 250px; background-color: white; color: black"
                >Cancel</v-btn
              >
            </v-card>
          </v-overlay>
        </v-card-title>
        <v-card-subtitle class="py-0 pl-0" style="color: #797979">
          {{ this.employee.id }}</v-card-subtitle
        >
      </v-col>
    </v-row>
    <v-card
      v-for="comp in this.employee.compliments"
      :key="comp.compliment"
      elevation="0"
      width="100%"
    >
      <v-card-title style="font-size: 15px; color: #797979" class="py-0 my-0">
        {{ new Date(comp.createdAt).toDateString() }} @
        {{ new Date(comp.createdAt).toLocaleTimeString() }}
      </v-card-title>
      <v-rating
        v-if="clientDetails.ratingStyle == 'stars'"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        half-icon="mdi-star-half-full"
        half-increments
        length="5"
        size="43"
        readonly
        color="yellow"
        background-color="yellow"
        :value="comp.rating"
        class="py-0 my-0"
      ></v-rating>
      <v-card-text v-if="clientDetails.ratingStyle=='slider'" class="py-0">
        {{ comp.rating }} / 10
      </v-card-text>
      <v-card-title class="pt-0">{{ comp.text }}</v-card-title>
      <hr>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "EmployeeData",

  data: () => ({
    deleteEmp: false,
    editEmp: false,
    employee: {},
    clientData: {},
  }),
  components: {},
  computed: {
    accessToken() {
      return this.$store.getters.accessToken;
    },
    employees() {
      return this.$store.getters.employees;
    },
    clientDetails() {
      return this.$store.getters.clientDetails;
    }
  },
  watch: {
    accessToken() {
      return this.$store.getters.accessToken;
    },
    employees() {
      return this.$store.getters.employees;
    },
    clientDetails() {
      return this.$store.getters.clientDetails;
    }
  },
  mounted() {
    this.$store.dispatch("setClientDetails");
    this.$store.dispatch("loadAllEmployees", {
      token: localStorage.getItem("token"),
      clientId: localStorage.getItem("clientId"),
    });
    this.loadEmployee();
  },
  methods: {
    async reloadEmployees() {
      if (!this.$route.params.id) {
        this.$router.push({ path: "/" }).catch(()=>{});
      }
      let id = this.$route.params.id;
      try {
        const response = await this.$http.get(
          "https://compliment-backend.herokuapp.com/users/?clientId=" +
            localStorage.getItem("clientId"),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.$store.dispatch("setEmployees", {"employees":response.data.employees});
      } catch (error) {
        console.log(error);
      }
      for (const element of this.employees) {
        if (element.id == id) {
          this.employee = element;
        }
      }
    },
    loadEmployee() {
      if (this.employees.length == 0) {
        for (var emp in this.employees) {
          if (this.employees[emp].id == this.$route.params.id) {
            this.employee = this.employees[emp];
          }
        }
      } else {
        this.reloadEmployees();
      }
    },
    routeTo() {
      this.$router.go(-1);
    },
  },
};
</script>