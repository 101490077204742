<template>
  <div>
    <v-card
      v-if="!isMobile"
      elevation="0"
      class="justify-center mt-3"
      width=""
      style=""
    >
      <v-row align="center" justify="center">
        <v-col cols="5">
          <div class="d-flex">
            <v-img
              alt="Compliment Logo"
              class="shrink mr-0 ml-3"
              contain
              src="../assets/ComplimentLogo.png"
              transition="scale-transition"
              width="60"
            />
            <v-card-title
              class=""
              style="
                color: black;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 40px;
                font-weight: 400;
                word-break: keep-all;
              "
            >
              compliment
            </v-card-title>
          </div>
          <v-card-subtitle
            style="font-weight: 700; font-size: 36px; line-height: 40px"
            >RECOGNITION MADE EASY</v-card-subtitle
          >
          <v-card-text
            style="font-size: 24px; margin-bottom: 25%; line-height: 30px"
          >
            Compliment allows customers, clients, and patients to recognize work
            done by their staff with simple app clips launched with just a tap.
          </v-card-text>
          <v-btn
            @click="routeTo('demo')"
            class="px-8"
            style="
              background-color: #fc7b81;
              color: white;
              font-size: 20px;
              margin-top: auto;
            "
          >
            Contact Us Today</v-btn
          >
        </v-col>
        <v-col cols="5">
          <v-img max-width="" src="../assets/iphone.png"></v-img>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-16">
        <v-col cols="10" class="ml-8"> </v-col>
      </v-row>
    </v-card>

    <v-card v-else elevation="0" class="justify-center mt-3" width="" style="">
      <v-col cols="12">
        <v-row>
          <v-img
            alt="Compliment Logo"
            class="shrink mr-0 ml-8"
            contain
            src="../assets/ComplimentLogo.png"
            transition="scale-transition"
            width="80"
          />
          <v-card-title
            class=""
            style="
              color: black;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 20px;
              font-weight: 400;
              word-break: keep-all;
            "
          >
            compliment
          </v-card-title>
        </v-row>
        <v-card-subtitle
          style="font-weight: 700; font-size: 20px; line-height: 20px"
          >RECOGNITION MADE EASY</v-card-subtitle
        >
        <v-card-text
          style="font-size: 24px; margin-bottom: 0%; line-height: 30px"
        >
          Compliment allows customers, clients, and patients to recognize work
          done by their staff with simple app clips launched with just a tap.
        </v-card-text>
        <v-btn
          @click="routeTo('demo')"
          class="ml-10"
          style="
            background-color: #fc7b81;
            color: white;
            font-size: 20px;
            margin-top: auto;
          "
        >
          Contact Us Today</v-btn
        >
      </v-col>
      <v-img max-width="" src="../assets/iphone.png"></v-img>
    </v-card>

    <v-card v-if="!isMobile" class="my-10">
      <v-card-subtitle
        class="text-center py-8"
        style="
          background-color: #274543;
          color: white;
          font-size: 25px;
          border-radius: 0px;
        "
        >No downloads, texts, or emails. Simply one tap of a phone for quick
        employee recognition.</v-card-subtitle
      >
    </v-card>

    <v-card v-else class="my-10">
      <v-card-subtitle
        class="text-center py-8"
        style="
          background-color: #274543;
          color: white;
          font-size: 25px;
          border-radius: 0px;
        "
        >No downloads, texts, or emails. Simply one tap of a phone for quick
        employee recognition.</v-card-subtitle
      >
    </v-card>

    <v-card
      elevation="0"
      align="center"
      class="mb-6"
      width="70%"
      height="auto"
      v-if="!isMobile"
      style="margin: auto; margin-top: 10%"
    >
      <v-card-subtitle
        class="text-center"
        style="font-size: 64px; line-height: 60px"
      >
        Want to experience using Compliment? Try our Beta program first!
      </v-card-subtitle>
      <v-card-subtitle style="font-size: 24px; line-height: 30px"
        >Compliment is offering a ‘beta to customer’ program to a limited number
        of new clients. Try our innovative system within your organization for
        free. After your trial ends, sign up using an exclusive discount.
      </v-card-subtitle>

      <v-btn
        @click="routeTo('demo')"
        style="background-color: #86d3cf; color: white"
        class="px-10 mt-10"
      >
        Get Compliment
      </v-btn>
    </v-card>

    <v-card
      elevation="0"
      align="center"
      class="mb-6"
      height="auto"
      v-else
      style="margin: auto; margin-top: 10%"
    >
      <v-card-subtitle
        class="mb-0 pb-0 text-center"
        style="font-size: 20px; color: black"
      >
        <b>Want to experience using Compliment? Try our Beta program first!</b>
      </v-card-subtitle>
      <v-card-subtitle style="font-size: 18px; line-height: 20px"
        >Compliment is offering a ‘beta to customer’ program to a limited number
        of new clients. Try our innovative system within your organization for
        free. After your trial ends, sign up using an exclusive discount.
      </v-card-subtitle>

      <v-btn
        @click="routeTo('demo')"
        style="background-color: #86d3cf; color: white"
        class="px-10 mt-10"
      >
        Get Compliment
      </v-btn>
    </v-card>

    <v-card
      class="py-10"
      v-if="!isMobile"
      style="background-color: #274543; color: white; margin-top: 10%"
      height="auto"
      elevation="0"
    >
      <v-card-subtitle
        class="d-flex justify-center mb-4 text-center mt-0"
        style="
          font-size: 46px;
          font-weight: 600;
          word-break: keep-all;
          line-height: 40px;
          color: white;
          margin-top: 3%;
        "
      >
        We provide what you need
      </v-card-subtitle>
      <v-card-subtitle
        class="text-center"
        style="
          background-color: #274543;
          color: white;
          font-size: 24px;
          border-radius: 0px;
          margin-right: 20%;
          margin-left: 20%;
        "
      >
        Compliments customizable app clips prompt a window to open when it comes
        in close contact with the chip or QR code we provide. This is where the
        review will begin.
      </v-card-subtitle>
    </v-card>

    <v-card
      class="py-10"
      v-else
      style="background-color: #274543; color: white; margin-top: 10%"
      height="auto"
      rounded="0"
      elevation="0"
    >
      <v-card-subtitle
        class="d-flex justify-center mb-4 text-center mt-0"
        style="
          font-size: 46px;
          font-weight: 600;
          word-break: keep-all;
          line-height: 40px;
          color: white;
          margin-top: 3%;
        "
      >
        We provide what you need
      </v-card-subtitle>
      <v-card-subtitle
        class="text-center"
        style="
          background-color: #274543;
          color: white;
          font-size: 24px;
          border-radius: 0px;
        "
      >
        Compliments customizable app clips prompt a window to open when it comes
        in close contact with the chip or QR code we provide. This is where the
        review will begin.
      </v-card-subtitle>
    </v-card>

    <v-card
      v-if="!isMobile"
      height="auto"
      class="py-16"
      width="80%"
      elevation="0"
      style="margin-left: auto; margin-right: auto"
    >
      <v-row class="mb-16">
        <v-col
          cols="8"
          class="ml-0 pl-0"
          style="margin-top: auto; margin-bottom: auto"
        >
          <v-card-title
            style="font-weight: 700; font-size: 36px; word-break: keep-all"
            class="pb-6 my-6"
          >
            Fast Feedback
          </v-card-title>
          <v-card-subtitle
            style="font-size: 24px; line-height: 30px"
            class="mb-6"
          >
            The prompted app clips appear on customer devices and once the
            review is complete, the window will automatically disappear.
          </v-card-subtitle>
          <v-btn
            @click="routeTo('demo')"
            class="ml-4 mt-10"
            style="background-color: #fc7b81; color: white"
          >
            Schedule a Demo
          </v-btn>
        </v-col>
        <v-col cols="4" align="center">
          <v-img
            max-width="200px"
            height="auto"
            src="../assets/complimentapp.gif"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row class="my-10">
        <v-col cols="6">
          <v-icon class="d-flex justify-start" size="200" color="#93d7e1">
            mdi-lock
          </v-icon>
        </v-col>
        <v-col cols="6" class="justify-center">
          <v-card-title
            class="pb-6"
            style="font-weight: 700; word-break: keep-all; font-size: 36px"
          >
            100% Client Protection
          </v-card-title>
          <v-card-subtitle style="font-size: 24px; line-height: 30px">
            Compliment does not collect personal data, so clients have
            confidence their data is safe when they leave a Compliment.
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-else
      height="auto"
      class="py-10 pb-0"
      elevation="0"
      style="margin-left: auto; margin-right: auto"
    >
      <v-col
        cols="12"
        class="ml-0 pl-0"
        style="margin-top: auto; margin-bottom: auto"
      >
        <v-card-title
          style="font-weight: 700; font-size: 36px; word-break: keep-all;"
          class="pb-6 my-6 justify-center"
        >
          Fast Feedback
        </v-card-title>
        <v-card-subtitle
          style="font-size: 24px; line-height: 30px"
          class="mb-6 text-center"
          
        >
          The prompted app clips appear on customer devices and once the review
          is complete, the window will automatically disappear.
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" align="center">
        <v-img
          max-width="200px"
          height="auto"
          src="../assets/complimentapp.gif"
        >
        </v-img>
        <v-btn
          @click="routeTo('demo')"
          class="ml-4 mt-10"
          style="background-color: #fc7b81; color: white"
        >
          Schedule a Demo
        </v-btn>
      </v-col>
      <v-col cols="12" class="justify-center">
        <v-card-title
          class="pb-6 text-center"
          style="font-weight: 700; word-break: keep-all; font-size: 36px"
        >
          100% Client Protection
        </v-card-title>
        <v-card-subtitle style="font-size: 24px; line-height: 30px" class="text-center">
          Compliment does not collect personal data, so clients have confidence
          their data is safe when they leave a Compliment.
        </v-card-subtitle>
      </v-col>
    </v-card>

    <v-card
      v-if="!isMobile"
      height="auto"
      class="pt-4"
      elevation="0"
      style="margin-left: auto; margin-right: auto; background-color: "
    >
      <v-row
        justify="center"
        style="margin-left: 10%; margin-right: 10%"
        class="mt-10"
      >
        <v-col cols="8">
          <v-card-title
            class="pa-0 pb-6"
            style="font-weight: 700; font-size: 36px; word-break: keep-all"
          >
            Lightning fast recognition from customers
          </v-card-title>
          <v-card-subtitle
            class="pa-0 ma-0 text-center"
            style="font-size: 24px; line-height: 30px"
          >
            We offer the easiest way for customers to recognize employees and
            give feedback to your business. Our services allow for quick,
            hassle-free reviews. No downloads, texts, or emails. With just the
            tap of a phone, customers send you data to help your business.
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-else
      height="auto"
      class=""
      elevation="0"
    >
      <v-row
        justify="center"
        class="mt-10"
      >
      <v-col cols="12">
        <v-icon class="d-flex justify-center" size="200" color="#93d7e1">
          mdi-lock
        </v-icon>
      </v-col>
        <v-col cols="12">
          <v-card-title
            class="pb-6 text-center"
            style="font-weight: 700; font-size: 36px; word-break: keep-all"
          >
            Lightning fast recognition from customers
          </v-card-title>
          <v-card-subtitle
            class="text-center"
            style="font-size: 24px; line-height: 30px"
          >
            We offer the easiest way for customers to recognize employees and
            give feedback to your business. Our services allow for quick,
            hassle-free reviews. No downloads, texts, or emails. With just the
            tap of a phone, customers send you data to help your business.
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <div class="py-4" align="center" v-if="!isMobile">
      <v-img class="" width="50%" src="../assets/ihpone mock.png"> </v-img>
    </div>
    <div class="py-4" align="center" v-else>
      <v-img class="" src="../assets/ihpone mock.png"> </v-img>
    </div>
    <v-flex
      v-if="!isMobile"
      class="py-5"
      style="
        background-image: linear-gradient(to right, #0e0e0e, #274543);
        color: white;
        height: auto;
      "
    >
      <div class="d-flex justify-center">
        <v-card-title>
          Need a new way to get recognition for your employees? Contact Us
          Today!
        </v-card-title>
      </div>
      <div class="d-flex justify-center py-4">
        <v-btn
          @click="routeTo('demo')"
          style="background-color: #86d3cf; color: black"
          class=""
        >
          Schedule A Demo
        </v-btn>
      </div>
    </v-flex>

    <v-flex
      v-else
      class="py-5"
      style="
        background-image: linear-gradient(to right, #0e0e0e, #274543);
        color: white;
        height: auto;
      "
    >
      <div class="d-flex justify-center text-center">
        <v-card-title style="word-break: normal;">
          Need a new way to get recognition for your employees? Contact Us
          Today!
        </v-card-title>
      </div>
      <div class="d-flex justify-center py-4">
        <v-btn
          @click="routeTo('demo')"
          style="background-color: #86d3cf; color: black"
          class=""
        >
          Schedule A Demo
        </v-btn>
      </div>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "DemoView",
  data: () => ({
    isMobile: false,
  }),
  methods: {
    routeTo(loc) {
      this.$router.push(loc).catch(() => {});
    },
  },
  mounted() {
    if (
      window.navigator.userAgent.match(/Android/i) ||
      window.navigator.userAgent.match(/iPhone/i) ||
      window.navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobile = true;
    }
  },
};
</script>


<style>
</style>