<template>
  <div class="d-flex" style="height: 100%">
    <SideNav parent="insight" />
    <InsightsData style="padding-bottom:100%"/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import InsightsData from "../components/InsightsData.vue";
export default {
  name: "InsightView",

  components: {
    SideNav,
    InsightsData,
  },
};
</script>
