<template>
  <div class="d-flex" style="height:100%">
    <SideNav parent="edit"/>
    <OrganizationData style="padding-bottom:100%"/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import OrganizationData from "../components/OrganizationData.vue";
export default {
  name: "CompanyView",

  components: {
    SideNav,
    OrganizationData,
},
};
</script>
