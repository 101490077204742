<template>
  <div style="background-color: #274543; height: 100%; width: 100%">
    <v-card elevation="0" align="center" style="background-color:#274543; color:white;">
      <v-card-title class="d-flex justify-center"> Contact Us </v-card-title>
      <v-card-subtitle style="color:white;">
        Leave us your details and we will contact you shortly!
      </v-card-subtitle>
    </v-card>

    <v-form v-model="valid" class="py-10">
      <v-container class="pa-0">
        <v-row justify="center" class="pa-0">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="pa-0" style="color: white">
              Name
            </v-card-subtitle>
            <v-text-field
              v-model="name"
              :rules="nameRules"
              required
              outlined
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="pa-0" style="color: white">
              Email
            </v-card-subtitle>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              required
              outlined
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="pa-0" style="color: white">
              Phone Number
            </v-card-subtitle>
            <v-text-field
              v-model="phone"
              outlined
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="pa-0" style="color: white">
              Company Name
            </v-card-subtitle>
            <v-text-field
              v-model="companyName"
              outlined
              background-color="white"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="pa-0" style="color: white">
              Description
            </v-card-subtitle>
            <v-textarea
              v-model="description"
              :counter="200"
              outlined
              background-color="white"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" class="pa-0">
            <v-btn class="px-8" style="background-color: #fc7b81; color: white" @click="submitForm">
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "DemoView",
  data: () => ({
    valid: false,
    name: "",
    companyName: "",
    nameRules: [(v) => !!v || "Name is required"],
    phone: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    description: "",
  }),
  methods: {
    async submitForm() {
      await axios.post("https://compliment-backend.herokuapp.com/landing", {"email":this.email, "name": this.name, "phone":this.phone, "companyName":this.companyName, "description": this.description});
    }
  }
};
</script>
