<template>
  <v-container class="mt-14">
    <v-row justify="center" class="text-center" style="font-size: 30px">
      <v-card-subtitle style="font-size: 24px; font-weight: 400"
        >Sorry! You can only submit a Compliment using a phone.</v-card-subtitle
      >
    </v-row>
    <v-row justify="center" class="text-center" style="font-size: 30px">
      <v-card-subtitle style="font-size: 36px; font-weight: 700"
        >Find a phone and try again!</v-card-subtitle
      >
    </v-row>
    <v-row justify="center" class="text-center">
      <v-img max-width="290" src="../assets/smartphone.gif"></v-img>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Error",
};
</script>
