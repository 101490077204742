<template>
  <v-card
    style="margin-right: auto; margin-left: auto; margin-top: 40px"
    class="justify-center text-center"
    width="90%"
    elevation="0"
  >
    <v-form ref="form" v-model="form">
      <v-card-subtitle style="font-size:15px;" class="py-0"><b> {{ prompt }}</b></v-card-subtitle>
      <v-slider
        v-if="this.ratingStyle == 'slider' && this.prompt"
        v-model="rating"
        step="1"
        ticks=""
        tick-size="4"
        thumb-label=""
        max="10"
        class="mx-4"
        prepend-icon="0"
        append-icon="10"
      ></v-slider>
      <v-rating v-if="this.ratingStyle == 'stars' && this.prompt"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        half-icon="mdi-star-half-full"
        half-increments
        length="5"
        size="35"
        color="yellow"
        background-color="yellow"
        v-model="rating"
      ></v-rating>
      <v-card-subtitle style="color: gray; font-size:15px;" v-if="this.prompt"> {{ rating }}</v-card-subtitle>
      <v-card-title style="word-break: normal; font-size: 15px;line-height: 17px;"><b> {{ textPrompt }} </b></v-card-title>
      <v-textarea
        outlined
        name=""
        v-model="text"
        label="Leave Feedback"
        :rules="[
          (v) =>
            (v || '').length <= 500 ||
            'Response must be 500 characters or less',
        ]"
        counter="500"
        class="mx-4"
      ></v-textarea>
    </v-form>
    <v-btn class="mb-4 px-16" rounded="" @click="submit" :disabled="!form"
      >Submit</v-btn
    >
    <v-card-text style="font-size:10px; line-height:15px">This recognition may be made viewable for company personnel and the public. By filling out this information and submitting it you are authorizing us to share it as described.</v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "SubmitCompliment",
  data: () => ({
    rating: 0,
    text: "",
    form: false,
    clientData: {},
    textPrompt: "",
    clientId: "",
    employeedId: "",
    prompt: "",
    ratingStyle: "",
  }),
  methods: {
    verifyMobile() {
      if (
        window.navigator.userAgent.match(/Android/i) == null &&
        window.navigator.userAgent.match(/iPhone/i) == null &&
        window.navigator.userAgent.match(/Windows Phone/i) == null
      ) {
        this.$router.push("error");
      }
    },
    async submit() {
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();
      data.append("clientId", this.clientId);
      data.append("employeeId", this.employeedId);
      data.append("text", this.text);
      data.append("rating", this.rating);

      var config = {
        method: "post",
        url: "https://compliment-backend.herokuapp.com/compliment",
        headers: {
          ...data.getHeaders,
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
        this.$router.push("/success");

    },
    async getClientDetails() {
      try {
        const response = await axios.get(
          "https://compliment-backend.herokuapp.com/client/" + this.clientId,
          {}
        );
        this.ratingStyle = response.data.ratingStyle;
        this.prompt = response.data.prompt;
        this.textPrompt = response.data.question;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if(window.location.search == "") {
      this.$router.push("/error");
    }
    var query = window.location.search.split('&');
    if(query.length != 2){
      this.$router.push("/error");
    }
    this.clientId = query[0].split('=')[1];
    this.employeedId = query[1].split('=')[1];
    if(this.clientId == "" || this.employeedId == "") {
      this.$router.push("/error")
    }
    this.verifyMobile();
    this.getClientDetails();
  },
  computed: {
    complimentData() {
      return this.$store.getters.complimentData;
    }
  },
};
</script>
