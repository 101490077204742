<template>
  <v-card width="350px" height="100%" style="background-color: #274543; border-radius: 0px;">
    <v-flex>
      <v-card-title>
        Company Name
        <v-spacer />
        <v-icon style="color: white"> mdi-account-circle-outline </v-icon>
      </v-card-title>
    </v-flex>
    <hr />

    <v-btn
      elevation="0"
      class="mt-2"
      :style="
        parent == 'employees'
          ? 'background-color: #274543; color: #86d3cf'
          : 'background-color: #274543; color: white'
      "
      @click="routeTo('dashboard')"
    >
      <v-icon class="mr-2"> mdi-account-group </v-icon>
      Reports
    </v-btn>
    <br/>
    
    <v-btn
      elevation="0"
      class="mt-2"
      :style="
        parent == 'edit'
          ? 'background-color: #274543; color: #86d3cf'
          : 'background-color: #274543; color: white'
      "
            @click="routeTo('view')"

    >
      <v-icon class="mr-2"> mdi-account-group </v-icon>
      Edit Organization
    </v-btn>
    <br/>
    <v-btn
      elevation="0"
      class="mt-2 text--lighten-5"
      :style="
        parent == 'insight'
          ? 'background-color: #274543; color: #86d3cf'
          : 'background-color: #274543; color: gray'
      "
    >
      <v-icon class="mr-2"> mdi-poll </v-icon>
      Insights (Coming Soon)
    </v-btn>
    <br/>
  </v-card>
</template>

<script>
export default {
  name: "SideNav",
  props: {
    parent: {
      type: String,
      required: true,
      default: "employees",
    },
  },
  methods: {
    routeTo(loc) {
      if (loc == "insight") {
        this.$router.push("/insight").catch(()=>{});
      }
      if (loc == "dashboard") {
        this.$router.push("dashboard").catch(()=>{});
      }
      if (loc == "add") {
        this.$router.push("/add").catch(()=>{});
      }
      if (loc == "view") {
        this.$router.push("/view").catch(()=>{});
      }
    },
  },
};
</script>

<style scoped>
* {
  color: white;
}

hr {
  background-color: #507471 !important;
  height: 1px;
  border: none;
}
</style>
