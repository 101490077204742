var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"background-color":"#274543","border-radius":"0px"},attrs:{"width":"350px","height":"100%"}},[_c('v-flex',[_c('v-card-title',[_vm._v(" Company Name "),_c('v-spacer'),_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v(" mdi-account-circle-outline ")])],1)],1),_c('hr'),_c('v-btn',{staticClass:"mt-2",style:(_vm.parent == 'employees'
        ? 'background-color: #274543; color: #86d3cf'
        : 'background-color: #274543; color: white'),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.routeTo('dashboard')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-group ")]),_vm._v(" Reports ")],1),_c('br'),_c('v-btn',{staticClass:"mt-2",style:(_vm.parent == 'edit'
        ? 'background-color: #274543; color: #86d3cf'
        : 'background-color: #274543; color: white'),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.routeTo('view')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-group ")]),_vm._v(" Edit Organization ")],1),_c('br'),_c('v-btn',{staticClass:"mt-2 text--lighten-5",style:(_vm.parent == 'insight'
        ? 'background-color: #274543; color: #86d3cf'
        : 'background-color: #274543; color: gray'),attrs:{"elevation":"0"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-poll ")]),_vm._v(" Insights (Coming Soon) ")],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }