<template>
  <div  style="height: 100%" class="d-flex">
    <SideNav parent="employees" />
    <AllEmployees />
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import AllEmployees from "@/components/AllEmployees.vue";
export default {
  name: "DashboardView",
  components: {
    SideNav,
    AllEmployees,
  },
  mounted() {

  },
  computed: {
    accessToken() {
      return this.$store.accessToken;
    }
  }
};
</script>
