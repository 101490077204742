<template>
  <div class="d-flex" style="height:100%">
    <SideNav parent="edit"/>
    <EditOrganization style="padding-bottom:100%"/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import EditOrganization from "../components/EditOrganization.vue";
export default {
  name: "EditView",

  components: {
    SideNav,
    EditOrganization
    },
};
</script>
