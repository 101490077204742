<template>
  <v-card width="65%" elevation="0" class="mb-10">
    <v-card-title class="d-flex ml-4"
      >All Reports
      <v-spacer />
      <v-icon style="color: black">mdi-magnify</v-icon>
      <input
        style="border: solid black 3px; border-radius: 5px; padding-left: 10px"
        type="text"
        v-model="search"
        placeholder="Search Employees..."
      />
    </v-card-title>

    <v-card
      elevation="0"
      v-for="emp in result"
      :key="emp.name"
      class="pt-2 d-flex ml-8 pb-0 rounded-0"
      style="border-bottom: 1px solid #c7c7c7"
      @click="routeTo(emp.id)"
    >
      <v-img
        :src="require('../assets/profile.png')"
        max-width="75px"
        height="70px"
        style="border-radius: 4px"
      >
      </v-img>
      <v-card elevation="0" class="pb-1">
        <v-card-title class="pt-0 pb-3">
          {{ emp.name }}
        <v-rating v-if="clientDetails.ratingStyle == 'stars'"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        half-icon="mdi-star-half-full"
        half-increments
        length="5"
        size="25"
        readonly
        dense
        :value="calculateAverage(emp.compliments)"
      ></v-rating>
        </v-card-title>
        <v-card-subtitle class="py-0">
          {{ emp.id }}
        </v-card-subtitle>
        
        <v-card-subtitle class="pt-0 pb-2">
          <strong>{{ emp.compliments.length }}</strong> Recognitions
        </v-card-subtitle>
      </v-card>
      <hr />
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "AllEmployees",
  data: () => ({
    search: "",
    result: [],
    average: 0.0,
  }),
  methods: {
    routeTo(id) {
      this.$router.push({
        name: "profile",
        params: {
          id: id,
        },
      }).catch(()=>{});
    },
    filterList() {
      this.result = [];
      for (const element of this.employees) {
        if (
          element.name
            .toLowerCase()
            .includes(this.search.toLowerCase())
        ) {
          this.result.push(element);
        }
      }
      return this.result;
    },
    calculateAverage(compliments) {
      let total = 0.0;
      let totalRatings = 0;
      for(const element of compliments) {
        if(element.rating) {
          total += element.rating;
          totalRatings++;
        }
      }
      return total / totalRatings;
    }
  },
  mounted() {
    this.$store.dispatch("loadAllEmployees", {
      token: localStorage.getItem("token"),
      clientId: localStorage.getItem("clientId"),
    });
    this.search =
      localStorage.getItem("search") != null
        ? localStorage.getItem("search")
        : "";
  },
  computed: {
    employees() {
      return this.$store.getters.employees;
    },
    accessToken() {
      return this.$store.getters.accessToken;
    },
    clientDetails() {
      return this.$store.getters.clientDetails;
    }
  },
  watch: {
    employees() {
      this.$store.getters.employees;
      this.filterList();
    },
    search() {
      localStorage.setItem("search", this.search);
      return this.filterList();
    },
    clientDetails() {
      return this.$store.getters.clientDetails;
    }
  },
};
</script>

<style scoped>
hr {
  height: 4px;
  background-color: black;
  border: none;
}
</style>
