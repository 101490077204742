<template>
  <v-card style="width:60%" elevation="0">
    <v-card-title class="mb-8" style="font-weight: 700">Insights</v-card-title>
    <v-card-subtitle 
      >Total Recognitions Recieved: {{ totalCompliments }}</v-card-subtitle
    >
    <GChart
      class="ml-0"
      type="LineChart"
      :options="options"
      :data="compliments"
    />
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
export default {
  name: "InsightsData",
  components: {
    GChart,
  },
  data: () => ({
    compliments: [
      ["Dates", "Compliments"],
      ["Aug 2020", 10],
      ["Sep 2020", 15],
      ["Oct 2020", 25],
      ["Nov 2020", 40],
      ["Dec 2020", 20],
      ["Jan 2021", 15],
      ["Feb 2021", 23],
      ["Mar 2021", 33],
    ],
    options: {
      title: "",
      legend: { position: "none" },
      hAxis: {
        textStyle: {
          color: "#000000",
        },
      },
      vAxis: {
        textStyle: {
          color: "#000000",
        },
      },
    },
    totalCompliments: 0,
  }),

  mounted() {
    this.totalCompliments = 0;
    for (let i = 1; i < this.compliments.length; i++) {
      this.totalCompliments += (this.compliments[i][1]);
    }
  },
};
</script>
