<template>
  <v-card width="65%" elevation="0">
    <v-form v-model="valid">
      <v-container class="mb-0">
        <v-card-title class="mb-3">
          <strong>Add New Employee</strong>
        </v-card-title>

        <v-card-subtitle class="mb-8">
          Fill out the form to add new employees to your organization. We will
          create the employee tags and send them to you!
        </v-card-subtitle>

        <v-row v-for="index in this.employees" :key="index" class="ml-4">
          <v-text-field
            v-model="first"
            label="First name"
            required
            outlined
            single-line
            class="mr-2 shrink"
          >
          </v-text-field>

          <v-text-field
            v-model="last"
            label="Last name"
            required
            single-line
            outlined
            class="mr-2 shrink"
          ></v-text-field>

          <v-text-field
            v-model="position"
            class="mr-2 shrink"
            label="Position"
            required
            single-line
            outlined
          ></v-text-field>

          <v-btn v-if="index != 1" class="mt-2" icon @click="employees--">
            <v-icon color="#c7c7c7">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-container>
      <v-btn @click="employees++" text depressed
        ><v-card-subtitle style="color: #797979"
          >+ <u>Add Another Employee</u></v-card-subtitle
        ></v-btn
      >
      <br />
      <v-btn
        class="ml-8 px-8 mt-12"
        style="background-color: #274543; color: white"
        @click="addEmployee"
        >Submit New Employees</v-btn
      >
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "AddEmployee",
  data: () => ({
    valid: false,
    first: "",
    last: "",
    email: "",
    position: "",
    managerId: 0,
    employees: 1,
    employeeType: "",
    clientId: "",
  }),
  methods: {
    async addEmployee() {
      try {
        const response = await axios.post(
          "https://compliment-backend.herokuapp.com/users/add",
          {
            name: this.first + " " + this.last,
            email: this.email,
            managerId: this.managerId,
            employeeType: this.employeeType,
            clientId: this.clientId,
          }
        );
        this.$store.dispatch("loadAllEmployees", {
          token: localStorage.getItem("token"),
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: unset !important;
}
</style>
