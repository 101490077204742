<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        elevation="0"
        v-on="on"
        style="background-color: white"
      >
        <v-icon color="#797979">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="py-2">
      <v-card-subtitle class="py-2" v-if="this.loggedIn"
        ><v-btn @click="routeTo('/')" class="py-0" text style=""
          ><v-icon class="mr-2">mdi-home</v-icon>Dashboard</v-btn
        ></v-card-subtitle
      >
      <v-card-subtitle class="py-2"
        ><v-btn @click="routeTo('demo')" class="py-0" text style=""
          ><v-icon class="mr-2">mdi-help-circle-outline</v-icon>Get Help</v-btn
        ></v-card-subtitle
      >
      <v-card-subtitle class="py-2">
        <v-btn v-if="this.loggedIn" @click="logout" class="py-0" text style="">
          <v-icon class="mr-2">mdi-logout</v-icon>Logout
        </v-btn>
        <v-btn v-else @click="setPopup" class="py-0" text style="">
          <v-icon class="mr-2">mdi-login</v-icon>Login
        </v-btn>
      </v-card-subtitle>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ProfileOptions",
  data: () => ({}),
  methods: {
    routeTo(place) {
      if(place == "demo")
      {
        this.$router.push("/demo").catch(()=>{});
      }
      else if (localStorage.getItem("token")) {
        this.$router.push("/dashboard").catch(()=>{});
      } 
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/").catch(()=>{});
      localStorage.clear();
    },
    setPopup() {
      if (!this.loggedIn) {
        this.$store.dispatch("loginPopUp");
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    popup() {
      return this.$store.getters.popup;
    },
  },
  watch: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    }
  }
};
</script>