import * as types from '../mutation-types';
import axios from 'axios';
import Vue from 'vue';
Vue.prototype.$http = axios;

export default {
    state: {
        employees: {},
        currEmployee: {},
        loggedIn: false,
        popup: false,
        accessToken: "",
        user: {},
        clientId: "",
        clientDetails: {},
        complimentData: {},
    },
    getters: {
        employees: state => state.employees,
        loggedIn: state => state.loggedIn,
        popup: state => state.popup,
        accessToken: state => state.accessToken,
        user: state => state.user,
        clientId: state => state.clientId,
        clientDetails: state => state.clientDetails,
        complimentData: state => state.complimentdata,
    },
    mutations: {
        [types.SET_EMPLOYEES](state, employees) {
            state.employees = employees;
        },
        [types.SET_LOGGEDIN](state, loggedIn) {
            state.loggedIn = loggedIn;
        },
        [types.SET_POPUP](state, popup) {
            state.popup = popup;
        },
        [types.SET_ACCESSTOKEN](state, accessToken) {
            state.accessToken = accessToken;
            localStorage.setItem("token", accessToken);
        },
        [types.SET_USER](state, user) {
            state.user = user;
        },
        [types.SET_CLIENTID](state, clientId) {
            localStorage.setItem("clientId", clientId);
            state.clientId = clientId;
        },
        [types.SET_CLIENTDETAILS](state, clientDetails) {
            localStorage.setItem("clientDetails", clientDetails);
            state.clientDetails = clientDetails;
        },
        [types.SET_COMPLIMENTDATA](state, data) {
            state.complimentData = data;
        }
    },

    actions: {
        async loadAllEmployees(ctx, data) {
            try {
                const response = await axios.get(
                    "https://compliment-backend.herokuapp.com/users/?clientId=" + data.clientId,
                    {
                        headers: {
                            Authorization: `Bearer ${data.token}`,
                        },
                    }
                )
                ctx.commit(types.SET_EMPLOYEES, response.data.employees)
                ctx.commit(types.SET_CLIENTID, data.clientId);
            } catch (error) {
                console.log(error);
            }
        },
        loginUser(ctx) {
            ctx.commit(types.SET_LOGGEDIN, true);
        },
        loginPopUp(ctx) {
            this.popup = !this.popup;
            ctx.commit(types.SET_POPUP, this.popup);
        },
        logout(ctx) {
            ctx.commit(types.SET_ACCESSTOKEN, "");
            ctx.commit(types.SET_LOGGEDIN, false);
        },
        setData(ctx, data) {
            ctx.commit(types.SET_ACCESSTOKEN, data.token);
            ctx.commit(types.SET_USER, data.user);
        },
        setClientId(ctx, data) {
            ctx.commit(types.SET_CLIENTID, data.clientId)
        },
        setEmployees(ctx, data) {
            ctx.commit(types.SET_EMPLOYEES, data.employees);
        },
        async setClientDetails(ctx) {
            var config = {
                method: 'get',
                url: 'https://compliment-backend.herokuapp.com/client/' + localStorage.getItem("clientId"),
                headers: { }
              };
              
              axios(config)
              .then(function (response) {
                var temp = {"id" : response.data.id, "name": response.data.name, "question": response.data.question, "ratingStyle": response.data.ratingStyle}
                ctx.commit(types.SET_CLIENTDETAILS, temp);

              })
              .catch(function (error) {
                console.log(error);
              });
        },
        setComplimentData(ctx, data) {
            ctx.commit(types.SET_COMPLIMENTDATA, data);
        }
    }
}