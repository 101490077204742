<template>
  <v-app>
    <v-overlay v-if="!isMobile" :value="popup">
      <v-card width="" class="px-0" style="background-color: white">
        <v-row class="mr-1" justify="end">
          <v-btn @click="closePopup" icon style="color: black">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
        </v-row>
        <v-card-title style="color: black">
          Sign in to Compliment
        </v-card-title>
        <v-img
          style="margin-right: auto; margin-left: auto"
          width="50px"
          src="./assets/ComplimentLogo.png"
        >
        </v-img>
        <v-text-field v-model="email" class="mx-4" light label="Email"
          >Email</v-text-field
        >
        <v-text-field
          v-model="password"
          light
          type="password"
          label="Password"
          class="pt-0 mt-0 mx-4"
          >Password</v-text-field
        >
        <v-btn text color="black" class="mb-3">
          <u>Forgot your password?</u>
        </v-btn>
        <v-row class="py-4">
          <v-btn
            style="margin-left: auto; margin-right: auto"
            @click="loginFunction"
          >
            Login
          </v-btn>
        </v-row>
        <v-card-title v-if="badLogin" style="color: red">
          Incorrect Email or Password
        </v-card-title>
      </v-card>
    </v-overlay>
    <v-app-bar  app color="white" elevation="1" style="" width="">
      <div class="d-flex">
        <v-img
          alt="Compliment Logo"
          class="shrink mr-0 ml-3"
          contain
          src="./assets/ComplimentLogo.png"
          transition="scale-transition"
          width="40"
        />
        <v-card-title @click="routeTo('/')" style="color: black; font-size:25px;font-family: 'Montserrat'; font-style: normal;font-weight: 400;font-size: 20px;">
          compliment
        </v-card-title>
      </div>

      <v-spacer v-if="!this.isMobile"></v-spacer>
      <v-row justify="end" v-if="!this.loggedIn && !this.isMobile">
        <v-btn @click="routeTo('solutions')" text style="color: black">
          Why Compliment
        </v-btn>
      </v-row>
      <v-btn v-if="!this.isMobile" @click="openMenu = !openMenu" text>
        <ProfileOptions />
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <FooterMenu />
  </v-app>
</template>

<script>
import FooterMenu from "./components/FooterMenu.vue";
import ProfileOptions from "./components/ProfileOptions.vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    FooterMenu,
    ProfileOptions,
  },
  data: () => ({
    openMenu: false,
    email: "",
    password: "",
    badLogin: false,
    isMobile: false,
  }),
  mounted() {
    if(localStorage.getItem("token") != null) 
    {
      this.$store.dispatch("loginUser");
    }
    if(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/Windows Phone/i))
    {
      this.isMobile = true;
    }
  },
  methods: {
    closePopup() {
      this.$store.dispatch("loginPopUp");
    },
    routeTo(destination) {
      this.$router.push(destination).catch(()=>{});
    },
    async loginFunction() {
      try {
        const response = await axios.post(
          "https://compliment-backend.herokuapp.com/users/login",
          {},
          {
            auth: {
              username: this.email,
              password: this.password,
            },
          }
        );
        this.$store.dispatch("setData", {
          token: response.data.token,
          user: response.data.user,
        });
        this.routeTo("/dashboard");
        this.$store.dispatch("loginUser");
        this.$store.dispatch("loginPopUp");
        localStorage.setItem("clientId", response.data.user.clientId);
        this.$store.dispatch("setClientDetails");
        this.$store.dispatch("loadAllEmployees", {
          token: response.data.token,
          clientId: response.data.user.clientId,
        });
        this.badLogin = false;
      } catch (error) {
        console.log(error);
        this.badLogin = true;
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    popup() {
      return this.$store.getters.popup;
    },
    accessToken() {
      return this.$store.getters.accessToken;
    },
  },
};
</script>

<style scoped>

#app {
  font-family:Arial, Helvetica, sans-serif;
}


</style>
