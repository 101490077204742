<template>
  <div>
    <v-card elevation="0" class="mt-3" width="" style="">
      <v-row align="end" justify="center">
        <v-col cols="5" class="mt-10 mb-6">
          <div class="d-flex">
            <v-img
              alt="Compliment Logo"
              class="shrink mr-0 ml-3"
              contain
              src="../assets/ComplimentLogo.png"
              transition="scale-transition"
              width="70"
            />
            <v-card-title
              class=""
              style="
                color: black;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 40px;
                font-weight: 400;
                word-break: keep-all;
              "
            >
              compliment
            </v-card-title>
          </div>
          <v-card-title
            style="
              font-weight: 700;
              font-size: 36px;
              line-height: 40px;
              word-break: keep-all;
            "
            >Why Compliment</v-card-title
          >
          <v-card-text style="font-size: 18px">
            Recognizing your employees for the work they do has endless benefits for your organization. Compliment gives you the power to gather feedback from customers to help your business practices.
          </v-card-text>
          <v-btn
            @click="routeTo('demo')"
            class="ml-4 my-8"
            style="background-color: #86d3cf; color: black"
          >
            Schedule Demo</v-btn
          >
        </v-col>
        <v-col cols="3" class="pa-0">
          <v-img class="mt-0" height="auto" src="../assets/fixed.png"></v-img>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-10">
      <v-card-subtitle
        class="text-center py-8"
        style="
          background-color: #274543;
          color: white;
          font-size: 25px;
          border-radius: 0px;
        "
        >Compliment provides recognition software and insights for every industry.
      </v-card-subtitle>
    </v-card>

    <v-card
      style="margin-left: auto; margin-right: auto; margin-top: 150px; margin-bottom:150px;"
      width="80%"
      elevation="0"
    >
      <v-row style="margin-bottom:150px" align="center">
        <v-col>
          <v-row>
            <v-col align="end" class="pa-0" cols="10">
              <v-card-subtitle
                style="font-weight: 700; font-size: 30px; line-height: 30px"
                align="start"
              >
                Increase employee retention
              </v-card-subtitle>
              <v-card-text
                style="font-size: 18px; font-weight: 400"
                align="start"
              >
              Compliment can be implemented in any clinic, health center, or hospital, giving patients the easiest way to highlight your staff's bedside manners and lifesaving skills.
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            style="border-radius: 10px"
            max-width="500px"
            src="../assets/christiann-koepke-dQyS2pMYtok-unsplash.jpeg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      style="margin-left: auto; margin-right: auto"
      width="80%"
      class="mt-6 mb-16"
      elevation="0"
    >
      <v-row align="center" style="margin-bottom:150px">
        <v-col>
          <v-row>
            <v-col align="start" class="pa-0" cols="10">
              <v-card-subtitle
                style="font-weight: 700; font-size: 30px; line-height: 30px"
                align="start"
              >
                Better job satisfaction and morale
              </v-card-subtitle>
              <v-card-text
                style="font-size: 18px; font-weight: 400"
                align="start"
              >
              Create a workplace of hard-working people who know that their work is appreciated. Compliments, recognition, and positive feedback will increase the morale of your entire organization.
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            style="border-radius: 10px"
            max-width="500px"
            src="../assets/solutionsTwo.jpeg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      align="center"
      style="
        background: linear-gradient(94.42deg, #86d3cf 54.47%,rgba(134, 211, 207, 0.21) 98.54%);
        text-align: auto;
        padding-top: 5%;
      "
      height="300px"
      elevation="0"
    >
      <v-card-text
        align="center"
        style="
          font-weight: 700;
          font-size: 32px;
          width: 80%;
          word-break: normal;
          line-height: 30px;
          color: black;
        "
      >
        Health Care? Travel? Service? Whoever your customer is, Compliment has a
        solution for every industry!
      </v-card-text>
      <v-row justify="center">
        <v-col cols="1">
          <v-img src="../assets/health.png"></v-img>
        </v-col>
        <v-col cols="1">
        <v-img src="../assets/Flight.png"></v-img>
      </v-col>
      <v-col cols="1">
        <v-img src="../assets/Fork.png"></v-img>
      </v-col>
      </v-row>
    </v-card>
    <v-card
      style="margin-left: auto; margin-right: auto; padding-top:10%"
      width="80%"
      class="mt-6"
      elevation="0"
    >
      <v-row align="center" style="margin-bottom:150px">
        <v-col>
          <v-row>
            <v-col align="center" class="pa-0" cols="10">
              <v-card-subtitle
                style="font-weight: 700; font-size: 30px; line-height: 30px"
                align="start"
              >
              Excellent customer experiences
              </v-card-subtitle>
              <v-card-text
                style="font-size: 18px; font-weight: 400"
                align="start"
              >
              Because Compliment gives you feedback from customers, you will have the opportunity to elevate their future experiences. Help customers feel like they are making a difference in the lives of your employees each time they leave a Compliment.              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            style="border-radius: 10px"
            max-width="500px"
            src="../assets/cdc-uN8TV9Pw2ik-unsplash.jpeg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      style="margin-left: auto; margin-right: auto"
      width="80%"
      class="mt-6 mb-16"
      elevation="0"
    >
      <v-row align="center" style="margin-bottom:150px">
        <v-col>
          <v-row>
            <v-col align="start" class="pa-0" cols="10">
              <v-card-subtitle
                style="font-weight: 700; font-size: 30px; line-height: 30px"
                align="start"
              >
              Increase employee productivity
              </v-card-subtitle>
              <v-card-text
                style="font-size: 18px; font-weight: 400"
                align="start"
              >
              Around 70% of employees said they would work harder if they knew they were appreciated (citation). Use Compliment to help them feel appreciated. 
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            style="border-radius: 10px"
            max-width="500px"
            src="../assets/Nurse image.jpeg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="pb-8 pt-4"
      align="center"
      elevation="0"
      style="
        margin-left: auto;
        margin-right: auto;
        border-radius: 0px;
        font-weight: 500;
        font-size: 20px;
        background: linear-gradient(90deg, #222222 5.45%, #507471 98.82%);
      "
    >
      <v-card-text style="word-break: normal; color:white; font-weight: 500; font-size:20px">
        Whomever your customer is, Compliment can help any industry thrive.        </v-card-text>
      <v-btn class="px-8" style="background-color: #86D3CF; color: black"
        >Schedule Demo</v-btn
      >
    </v-card>
  </div>
</template>

 <script>
export default {
  name: "Solutions",
  methods: {
    routeTo(dest) {
      this.$router.push(dest).catch(()=>{});
    },
  },
};
</script>
 