import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from 'axios';
import store from './store';
import Vuex from 'vuex'

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  Vuex,
  render: (h) => h(App),
}).$mount("#app");
