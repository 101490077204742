<template>
  <div class="d-flex" style="height:100%">
    <SideNav parent="employees" />
    <EmployeeData style="padding-bottom:100%"/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import EmployeeData from "../components/EmployeeData.vue";
export default {
  name: "EmployeeProfile",
  components: {
    EmployeeData,
    SideNav,
  },
};
</script>
