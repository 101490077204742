<template>
  <v-footer bottom style="background-color: #111111;" class="foot">
    <div class="d-flex mb-auto" style="">
      <v-img
        alt="../assets/ComplimentLogo.png"
        class="shrink mr-0 ml-3"
        contain
        src="../assets/ComplimentWhite.png"
        transition="scale-transition"
        width="40"
      />
      <v-card-title style="color: white; font-size:25px;font-family: 'Montserrat';font-style: normal;font-weight: 400;" class="pt-2">
        compliment
      </v-card-title>
    </div>
    <v-spacer></v-spacer>
    <div class="flex-column mr-12">
      <v-card-title class="pa-0 mb-4" style="color: white; font-weight:700"> Need Help? </v-card-title>
      <v-card-subtitle class="pa-0" style="color:white"> Info@wecompliment.com </v-card-subtitle>
      <v-btn icon href="https://www.instagram.com/we.compliment/?igshid=YmMyMTA2M2Y%3D"><v-icon color="white" class="pl-0">mdi-instagram</v-icon></v-btn> 
      <v-btn icon href="www.linkedin.com"><v-icon class="pl-0" color="white">mdi-linkedin</v-icon></v-btn>
      <v-card-text class="pa-0 my-8" style="color:white; font-size:12px"> Copyright @ 2022 Compliment </v-card-text>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>


<style scoped>
 .foot {
    margin-top:auto;
     right:0;
     bottom:0;
     left:0;
 }
</style>