<template>
  <div class="d-flex" style="height: 100%">
    <SideNav parent="add" />
    <AddEmployee style="padding-bottom:100%"/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import AddEmployee from "../components/AddEmployee.vue";
export default {
  name: "AddView",

  components: {
    SideNav,
    AddEmployee,
  },
};
</script>
