<template>
  <div>
    <LandingView/>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import AllEmployees from "../components/AllEmployees.vue";
import LandingView from "./LandingView.vue";
export default {
  name: "HomeView",

  data: () => ({
  }),
  components: {
    SideNav,
    AllEmployees,
    LandingView,
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>
