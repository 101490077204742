import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import InsightView from "../views/InsightView.vue";
import AddView from "../views/AddView.vue";
import EditView from "../views/EditView.vue";
import CompanyView from "../views/CompanyView";
import EmployeeProfileView from "../views/EmployeeProfileView.vue";
import DemoView from "../views/DemoView.vue";
import DashboardView from "../views/DashboardView.vue";
import SolutionsView from "../views/SolutionsView.vue";
import store from "@/store";
import SubmitCompliment from "../views/SubmitCompliment.vue";
import ErrorView from "../views/ErrorView.vue";
import SubmitSuccess from "../views/SubmitSuccess.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/insight",
    name: "insight",
    component: InsightView,
  },
  {
    path: "/add",
    name: "add",
    component: AddView,
  },
  {
    path: "/edit",
    name: "edit",
    component: EditView,
  },
  {
    path: "/view",
    name: "view",
    component: CompanyView,
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: EmployeeProfileView,
  },
  {
    path: "/demo",
    name: "demo",
    component: DemoView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/solutions",
    name: "solutions",
    component: SolutionsView,
  },
  {
    path: "/compliment",
    name: "compliment",
    component: SubmitCompliment,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/success",
    name: "success",
    component: SubmitSuccess,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token")
  if ((to.path == "/solutions" && from.path == "/") || (to.path=="/demo" && to.query == {}) || to.path=="/error" || to.path=="/success") {
    return next();
  }
  if(to.path.includes("/test") || to.path.includes("/demo")) {
    next({path:"/compliment", query: {client: to.query.client, id: to.query.id}})
    return next();
  }
  if(to.path.includes("/compliment")) {
    store.dispatch("setComplimentData", to.query);
    if(!(window.navigator.userAgent.match(/Android/i) || window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/Windows Phone/i)))
    {
      next({ name: "error" });
    }
    return next();
  }
  if (to.path !== "/" && from.path !== "/solutions") {
    if (!token) next({ name: "home" });
    return next();
  }
  return next();
});

export default router;
